import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, CircularProgress, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://truckcodes.pl/api/login', {
        email,
        password
      });
      const token = response.data.token;
      sessionStorage.setItem('token', token);
      console.log(response.data);
      navigate('/browser');
    } catch (error) {
      setError('Niepoprawny login lub hasło');
    } finally {
      setLoading(false); 
    } 
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Logowanie
        </Typography>
        <Typography variant="h6" sx={{mb:4}}>Trucks codes <LocalShippingIcon fontSize="large" sx={{mb:-1}}/></Typography>
        <TextField
          label="Login"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Hasło"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          fullWidth
          style={{ marginTop: '20px', height: '35px', position: 'relative' }}
        >
           {loading ? <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px', color: '#222' }} /> : 'Zaloguj się'}
        </Button>
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Container>
  );
};

export default Login;
