import './App.css';
import SignIn from './components/SignIn/SignIn';
import Browser from './components/Browser/Browser';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


function App() {

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  darkTheme.typography.h2 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [darkTheme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  return (
    <BrowserRouter>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/browser" element={<Browser />} />
        </Routes>
    </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
