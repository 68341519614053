import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Typography, Button, IconButton } from '@mui/material';
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const SearchTable = () => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if(!token) {
      navigate('/'); 
    }

    const fetchData = async () => {
      if (query.length >= 2) {
        setLoading(true);
        const token = sessionStorage.getItem('token');
        try {
          const response = await axios.get(`https://truckcodes.pl/api/search?query=${query}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setData(response.data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [query]);

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleLogout = async () => {
    const token = sessionStorage.getItem('token');
    setLogout(true);
    try {
      await axios.post('https://truckcodes.pl/api/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      sessionStorage.removeItem('token');
      navigate('/');
      setLogout(false);
    } catch (err) {
      console.error('Logout failed:', err);
      setLogout(false);
    }
  };

  const renderTable = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Typography color="error">Wystąpił błąd</Typography>;
    }

    if (query.length >= 2 && data.length === 0) {
      return <Typography>Brak pasujących wyników</Typography>;
    }

    if (data.length === 0) {
      return null;
    }

    const columns = Object.keys(data[0]).filter(key => key !== 'id' && data.some(row => row[key] !== null && row[key] !== ''));

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Kod</TableCell>
              <TableCell sx={{minWidth: "200px"}}>Parametr</TableCell>
              <TableCell sx={{minWidth: "200px"}}>FMI</TableCell>
              <TableCell sx={{minWidth: "300px"}}>Opis</TableCell>
              <TableCell>Silnik</TableCell>
              <TableCell sx={{minWidth: "200px"}}>System</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.type ? row.type : '-'}</TableCell>
                <TableCell>{row.code ? row.code : '-'}</TableCell>
                <TableCell>{row.parameter ? row.parameter : '-'}</TableCell>
                <TableCell>{row.fmi1 ? row.fmi1 : '-'}</TableCell>
                <TableCell>{row.description ? row.description  : '-'}</TableCell>
                <TableCell>{row.engine ? row.engine : '-'}</TableCell>
                <TableCell>{row.system ? row.system : '-'}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2} fullWidth>
      <Box
      lg={{
        pr: "6rem",
        pl: "6rem",
        width: 1,
        display: "flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginBottom: "2rem"
      }}
      sx={{
        pr: "2rem",
        pl: "2rem",
        width: 1,
        display: "flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginBottom: "2rem"
      }}
      >
        <Typography variant="h2">Trucks codes <LocalShippingIcon fontSize="large" sx={{mb:-1}}/></Typography>
        <IconButton 
        aria-label="logout" 
        size="large"
        position="relative"
        onClick={handleLogout}
        >
        <LogoutIcon fontSize="inherit" />
        {logout ? <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '-50%', marginTop: '-12px', marginLeft: '-12px', color: '#fff' }} /> : ''}
        </IconButton>
      </Box>

      <TextField
        label="Wyszukaj kod błędu"
        variant="outlined"
        fullWidth
        onChange={handleSearchChange}
        value={query}
        style={{ marginBottom: '20px', maxWidth: '600px' }}
      />
      {renderTable()}

    </Box>
  );
};

export default SearchTable;
